.main {
  width: 100%;
}
.main .indexMain .indexBanner {
  width: 100%;
  background: rgba(240, 240, 240, 0.7);
  padding: 15px;
}
.main .indexMain .indexBanner .swBnnerBox {
  max-width: 1100px;
  margin: 0 auto;
  position: relative;
}
.main .indexMain .indexBanner .swBnnerBox .bannerBox {
  max-width: 100%;
}
.main .indexMain .indexBanner .swBnnerBox .bannerBox img {
  width: 100%;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-pagination-bullet {
  opacity: 0.8;
  width: 10px;
  border-radius: 10px;
  height: 10px;
  background: #fff;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-pagination-bullet-active {
  opacity: 1;
  background: #0065AF;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-next {
  background: url("../static/u173.svg") no-repeat;
  width: 30px;
  height: 50px;
  opacity: 0.7;
  -webkit-tap-highlight-color: transparent;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-next:hover {
  -webkit-tap-highlight-color: transparent;
  background: url("../static/u173_mouseOver.svg") no-repeat;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-next:active {
  -webkit-tap-highlight-color: transparent;
  text-decoration: none;
  border: none;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-next:after {
  display: none;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-prev {
  width: 30px;
  height: 50px;
  opacity: 0.7;
  -webkit-tap-highlight-color: transparent;
  background: url("../static/u174.svg") no-repeat;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-prev:hover {
  -webkit-tap-highlight-color: transparent;
  background: url("../static/u174_mouseOver.svg") no-repeat;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-prev:active {
  text-decoration: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
}
.main .indexMain .indexBanner .swBnnerBox .swiper-button-prev:after {
  display: none;
}
.main .indexMain .indexLinkList {
  padding: 25px 15px 10px 15px;
}
.main .indexMain .indexLinkList .iListBox {
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem {
  overflow: hidden;
  cursor: pointer;
  width: 24%;
  background: #fff;
  border: 1px solid #f2f2f2;
  position: relative;
  padding-bottom: 33px;
  margin-bottom: 15px;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a img {
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.2s linear;
  /* Safari 和 Chrome */
  -o-transition: all 0.2s linear;
  /* Opera */
  width: 100%;
  transform: scale(1);
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a p {
  background: #f2f2f2;
  position: absolute;
  width: 100%;
  height: 36px;
  line-height: 36px;
  padding-left: 15px;
  left: 0;
  font-size: 14px;
  bottom: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a p img {
  width: 8px;
  margin-right: 7px;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a p sup {
  vertical-align: top;
  height: 32px;
  line-height: normal;
  margin-left: 3px;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a p sup svg {
  width: 16px;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a:hover img {
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.2s linear;
  /* Safari 和 Chrome */
  -o-transition: all 0.2s linear;
  /* Opera */
  transform: scale(1.2);
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a:hover p {
  background: #0065af;
  color: #fff;
}
.main .indexMain .indexLinkList .iListBox .indexLinkListItem a:hover p sup svg {
  fill: #fff;
}
.main .indexMain .newsMain {
  width: 100%;
  padding: 15px;
}
.main .indexMain .newsMain .newsBox {
  max-width: 1100px;
  margin: 0 auto;
}
.main .indexMain .newsMain .newsBox .newsTitle {
  text-align: center;
  font-size: 30px;
  color: #333;
  padding: 25px 0;
}
.main .indexMain .newsMain .newsBox .newsList {
  max-width: 960px;
  margin: 0 auto;
  border-top: 2px solid #f0f0f0;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 20px 0 15px 0;
  border-bottom: 2px solid #f0f0f0;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTime {
  font-size: 16px;
  width: 20%;
  text-align: right;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType {
  width: 12%;
  text-align: center;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType span {
  color: #fff;
  text-align: center;
  font-size: 13px;
  padding: 3px 0;
  display: inline-block;
  width: 7em;
  background: #02a7f0;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType span.fz {
  background: #00a62e;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType span.ry {
  background: #d9001b;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType span.dx {
  background: #0065af;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle {
  width: 58%;
  padding: 0 20px 0 0;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle a h3 {
  color: #333;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle a p {
  font-size: 14px;
  color: #555;
  padding-left: 20%;
}
.main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle a:hover {
  text-decoration: underline;
}
.main .indexMain .linkMain {
  width: 100%;
  background: rgba(240, 240, 240, 0.7);
  padding: 15px;
}
.main .indexMain .linkMain .linkBox {
  max-width: 1100px;
  margin: 0 auto;
}
.main .indexMain .linkMain .linkBox .linkTitle {
  font-size: 20px;
  color: #333;
  padding: 30px 0.5%;
}
.main .indexMain .linkMain .linkBox .linkList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-content: flex-start;
}
.main .indexMain .linkMain .linkBox .linkList .linkListItem {
  width: 24%;
  height: 84px;
  margin: 0 0.5% 15px 0.5%;
}
.main .indexMain .linkMain .linkBox .linkList .linkListItem a {
  height: 84px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  border: 1px solid #dbdbdb;
  padding: 0 20px;
}
.main .indexMain .linkMain .linkBox .linkList .linkListItem a img {
  max-height: 40px;
  margin-right: 10px;
}
.main .indexMain .linkMain .linkBox .linkList .linkListItem a h3 {
  font-size: 16px;
  color: #5E5E5E;
  font-weight: bold;
  text-align: center;
}
.main .indexMain .linkMain .linkBox .linkList .linkListItem:hover a {
  border-color: #02a7f0;
}
.main .indexMain .activityMain {
  width: 100%;
  background: rgba(240, 240, 240, 0.7);
  padding: 15px 15px 30px 15px;
}
.main .indexMain .activityMain .activityBox {
  max-width: 1100px;
  margin: 0 auto;
}
.main .indexMain .activityMain .activityBox .activityTitle {
  font-size: 20px;
  color: #333;
  padding: 30px 0.5%;
}
.main .indexMain .activityMain .activityBox .activityList {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.main .indexMain .activityMain .activityBox .activityList .activityListItem {
  width: 49%;
  margin: 0 0.5% 15px 0.5%;
  overflow: hidden;
}
.main .indexMain .activityMain .activityBox .activityList .activityListItem a {
  display: block;
}
.main .indexMain .activityMain .activityBox .activityList .activityListItem a img {
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.2s linear;
  /* Safari 和 Chrome */
  -o-transition: all 0.2s linear;
  /* Opera */
  transform: scale(1);
  width: 100%;
}
.main .indexMain .activityMain .activityBox .activityList .activityListItem a:hover img {
  transition: all 0.2s linear;
  -moz-transition: all 0.2s linear;
  /* Firefox 4 */
  -webkit-transition: all 0.2s linear;
  /* Safari 和 Chrome */
  -o-transition: all 0.2s linear;
  /* Opera */
  transform: scale(1.1);
}
@media screen and (max-width: 1100px) {
  .main .indexMain .indexBanner {
    padding: 0;
  }
  .main .indexMain .linkMain .linkBox .linkList .linkListItem {
    width: 32%;
  }
}
@media screen and (max-width: 880px) {
  .main .indexMain .indexLinkList {
    padding: 15px 15px 10px 15px;
  }
  .main .indexMain .indexBanner {
    padding: 0;
  }
  .main .indexMain .indexBanner .swBnnerBox .swiper-button-next {
    width: 15px;
    height: 27px;
    background-size: 100%;
  }
  .main .indexMain .indexBanner .swBnnerBox .swiper-button-next:hover {
    width: 15px;
    height: 27px;
    background-size: 100%;
  }
  .main .indexMain .indexBanner .swBnnerBox .swiper-button-prev {
    width: 15px;
    height: 27px;
    background-size: 100%;
  }
  .main .indexMain .indexBanner .swBnnerBox .swiper-button-prev:hover {
    width: 15px;
    height: 27px;
    background-size: 100%;
  }
  .main .indexMain .indexBanner .swBnnerBox .swiper-pagination-bullet {
    width: 6px;
    height: 6px;
  }
  .main .indexMain .newsMain .newsBox .newsList .newsListItem {
    display: inherit;
  }
  .main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTime {
    display: initial;
    margin-right: 15px;
  }
  .main .indexMain .newsMain .newsBox .newsList .newsListItem .itemType {
    display: inline-block;
  }
  .main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle {
    padding: 15px 0 5px 0;
    width: 100%;
  }
  .main .indexMain .newsMain .newsBox .newsList .newsListItem .itemTitle p {
    padding-left: 0;
  }
}
@media screen and (max-width: 765px) {
  .main .indexMain .indexLinkList .iListBox {
    flex-wrap: wrap;
  }
  .main .indexMain .indexLinkList .iListBox .indexLinkListItem {
    margin-bottom: 15px;
    width: 49%;
    font-size: 11px;
  }
  .main .indexMain .indexLinkList .iListBox .indexLinkListItem p {
    padding-left: 6px;
  }
  .main .indexMain .indexLinkList .iListBox .indexLinkListItem p img {
    width: 6px;
    margin-right: 5px;
    vertical-align: middle;
  }
  .main .indexMain .linkMain .linkBox .linkList .linkListItem {
    width: 49%;
  }
  .main .indexMain .activityMain .activityBox .activityList .activityListItem {
    width: 100%;
  }
}
@media screen and (max-width: 481px) {
  .main .indexMain .linkMain .linkBox .linkList .linkListItem {
    width: 100%;
  }
  .main .indexMain .linkMain .linkBox .linkList .linkListItem h3 {
    flex: initial;
  }
}
