.main {
  width:100%;
  .indexMain{
    .indexBanner{
      width: 100%;
      background: rgba(240, 240, 240, 0.7);
      padding:15px;
      .swBnnerBox{
        max-width: 1100px;
        margin:0 auto;
        position: relative;
        .bannerBox{
          max-width: 100%;
          img{
            width:100%;
          }
        }
        .swiper-pagination-bullet{
          opacity: .8;
          width:10px;
          border-radius: 10px;
          height:10px;
          background: #fff;
        }
        .swiper-pagination-bullet-active{
          opacity: 1;
          background: #0065AF;
        }
        .swiper-button-next{
          background: url("../static/u173.svg") no-repeat;
          width:30px;
          height:50px;
          opacity: .7;
          -webkit-tap-highlight-color:transparent;
          &:hover{
            -webkit-tap-highlight-color:transparent;
            background: url("../static/u173_mouseOver.svg") no-repeat;
          }
          &:active{
            -webkit-tap-highlight-color:transparent;
            text-decoration: none;
            border:none;
          }
          &:after{
            display: none;
          }
        }
        .swiper-button-prev{
          width:30px;
          height:50px;
          opacity: .7;
          -webkit-tap-highlight-color:transparent;
          background: url("../static/u174.svg") no-repeat;
          &:hover{
            -webkit-tap-highlight-color:transparent;
            background: url("../static/u174_mouseOver.svg") no-repeat;
          }
          &:active{
            text-decoration: none;
            border:none;
            -webkit-tap-highlight-color:transparent;
          }
          &:after{
            display: none;
          }
        }
      }
    }
    .indexLinkList{
      padding:25px 15px 10px 15px;
      .iListBox{
        max-width: 1100px;
        margin:0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .indexLinkListItem{
          overflow: hidden;
          cursor: pointer;
          width:24%;
          background:#fff;
          border: 1px solid rgba(242, 242, 242, 1);
          position: relative;
          padding-bottom:33px;
          margin-bottom:15px;
          a{
            img{
              transition: all .2s linear;
              -moz-transition:all .2s linear; /* Firefox 4 */
              -webkit-transition: all .2s linear; /* Safari 和 Chrome */
              -o-transition: all .2s linear; /* Opera */
              width:100%;
              transform:scale(1);
            }
            p{
              background:  rgba(242, 242, 242, 1);
              position: absolute;
              width:100%;
              height:36px;
              line-height: 36px;
              padding-left:15px;
              left:0;
              font-size: 14px;
              bottom:0;
              overflow:hidden;
              white-space:nowrap;
              text-overflow: ellipsis;
              display: flex;
              align-items: center;
              img{
                width:8px;
                margin-right:7px;
              }
              sup{
                vertical-align:top;height: 32px;line-height: normal;margin-left: 3px;
                svg{
                  width:16px;
                }
              }
            }
            &:hover{
              img{
                transition: all .2s linear;
                -moz-transition:all .2s linear; /* Firefox 4 */
                -webkit-transition: all .2s linear; /* Safari 和 Chrome */
                -o-transition: all .2s linear; /* Opera */
                transform:scale(1.2);
              }
              p{
                background: #0065af;
                color:#fff;
                sup{
                  svg{
                    fill: #fff;
                  }
                }
              }
            }
          }
        }
      }
    }
    .newsMain{
      width: 100%;
      padding:15px;
      .newsBox{
        max-width:1100px;
        margin:0 auto;
        .newsTitle{
          text-align: center;
          font-size: 30px;
          color:#333;
          padding:25px 0;
        }
        .newsList{
          max-width:960px;
          margin:0 auto;
          border-top:2px solid rgba(240, 240, 240, 1);
          .newsListItem{
            display: flex;
            width:100%;
            justify-content: space-between;
            padding:20px 0 15px 0;
            border-bottom:2px solid rgba(240, 240, 240, 1);
            .itemTime{
              font-size: 16px;
              width:20%;
              text-align: right;
            }
            .itemType{
              width:12%;
              text-align: center;
              span{
                color:#fff;
                text-align: center;
                font-size: 13px;
                padding: 3px 0;
                display: inline-block;
                width: 7em;
                background: rgba(2, 167, 240, 1);
                &.fz{
                  background: rgba(0, 166, 46, 1);
                }
                &.ry{
                  background: rgba(217, 0, 27, 1);
                }
                &.dx{
                  background: rgba(0, 101, 175, 1);
                }
              }
            }
            .itemTitle{
              width:58%;
              padding:0 20px 0 0;
              a{
                h3{
                  color:#333;
                  font-weight: bold;
                  font-size: 16px;
                  margin-bottom:10px;
                }
                p{
                  font-size: 14px;
                  color:#555;
                  padding-left:20%;
                }
                &:hover{
                  text-decoration: underline;
                }
              }
            }
          }
        }
      }
    }
    .linkMain{
      width: 100%;
      background: rgba(240, 240, 240, 0.7);
      padding:15px;
      .linkBox{
        max-width:1100px;
        margin:0 auto;
        .linkTitle{
          font-size: 20px;
          color:#333;
          padding:30px .5%;
        }
        .linkList{
          display: flex;
          justify-content: flex-start;
          flex-wrap: wrap;
          align-content: flex-start;
          .linkListItem{
            width:24%;
            height:84px;
            margin:0 .5% 15px .5%;
            a{
              height:84px;
              width:100%;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #fff;
              border: 1px solid rgba(219, 219, 219, 1);
              padding:0 20px;
              img{
                max-height: 40px;
                margin-right:10px;
              }
              h3{
                font-size: 16px;
                color:#5E5E5E;
                font-weight: bold;
                text-align: center;
              }
            }
            &:hover{
              a{
                border-color: #02a7f0;
              }
            }
          }
        }
      }
    }
    .activityMain{
      width: 100%;
      background: rgba(240, 240, 240, 0.7);
      padding:15px 15px 30px 15px;
      .activityBox{
        max-width:1100px;
        margin:0 auto;
        .activityTitle{
          font-size: 20px;
          color:#333;
          padding:30px .5%;
        }
        .activityList{
          display: flex;
          justify-content:flex-start;
          flex-wrap: wrap;
          .activityListItem{
            width:49%;
            margin:0 0.5% 15px 0.5%;
            overflow: hidden;
            a{
              display: block;
              img{
                transition: all .2s linear;
                -moz-transition:all .2s linear; /* Firefox 4 */
                -webkit-transition: all .2s linear; /* Safari 和 Chrome */
                -o-transition: all .2s linear; /* Opera */
                transform:scale(1);
                width:100%;
              }
              &:hover{
                img{
                  transition: all .2s linear;
                  -moz-transition:all .2s linear; /* Firefox 4 */
                  -webkit-transition: all .2s linear; /* Safari 和 Chrome */
                  -o-transition: all .2s linear; /* Opera */
                  transform:scale(1.1);
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1100px) {
  .main{
    .indexMain{
      .indexBanner{
        padding:0;
      }
      .linkMain{
        .linkBox{
          .linkList{
            .linkListItem{
              width:32%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 880px) {
  .main{
    .indexMain{
      .indexLinkList{
        padding: 15px 15px 10px 15px;
      }
      .indexBanner{
        padding:0;
        .swBnnerBox{
          .swiper-button-next{
            width:15px;
            height: 27px;
            background-size: 100%;
            &:hover{
              width:15px;
              height: 27px;
              background-size: 100%;
            }
          }
          .swiper-button-prev{
            width:15px;
            height: 27px;
            background-size: 100%;
            &:hover{
              width:15px;
              height: 27px;
              background-size: 100%;
            }
          }
          .swiper-pagination-bullet{
            width:6px;
            height:6px;
          }
        }
      }
      .newsMain{
        .newsBox{
          .newsList{
            .newsListItem{
              display: inherit;
              .itemTime{
                display: initial;
                margin-right:15px;
              }
              .itemType{
                display: inline-block;;
              }
              .itemTitle{
                padding:15px 0 5px 0;
                width:100%;
                p{
                  padding-left:0;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 765px) {
  .main{
    .indexMain{
      .indexLinkList{
        .iListBox{
          flex-wrap: wrap;
          .indexLinkListItem{
            margin-bottom:15px;
            width:49%;
            font-size: 11px;
            p{
              padding-left:6px;
              img{
                width:6px;
                margin-right:5px;
                vertical-align: middle;
              }
            }
          }
        }
      }
      .linkMain{
        .linkBox{
          .linkList{
            .linkListItem{
              width:49%;
            }
          }
        }
      }
      .activityMain{
        .activityBox{
          .activityList{
            .activityListItem{
              width:100%;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 481px) {
  .main{
    .indexMain{
      .linkMain{
        .linkBox{
          .linkList{
            .linkListItem{
              width:100%;
              h3{
                flex: initial;
              }
            }
          }
        }
      }
    }
  }
}